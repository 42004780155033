import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './Server.module.css';
import { fetchAPIInternal } from '../utils/api';
import { formatLocalTime } from '../utils/time';
import TimerComponent from '../components/TimerComponent';
import CopyToClipboard from '../components/CopyToClipboard';
import Location from '../components/Location';
import Modal from 'react-modal';

// Define the structure of the server status response
interface ServerStatusResponse {
  guid?: string;
  status?: string;
  created_at?: string;
  started_at?: string;
  server_ip?: string;
  region?: string;
  password?: string;
  players?: number;
  ready_to_play_in_seconds?: number;
}

Modal.setAppElement('#root'); // Set the root element for the modal

export const Server: React.FC = () => {
  const params = useParams<{ guid: string }>();
  const navigate = useNavigate();
  const [serverStatus, setServerStatus] = useState<ServerStatusResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isRestartingServer, setIsRestartingServer] = useState(false); // State to track loading
  const [isModalOpen, setIsModalOpen] = useState(false); // State to track modal open/close

  const handleRestart = () => {
    if (params.guid) {
      setIsRestartingServer(true);
      fetchAPIInternal(`/restart-server/${params.guid}`, { method: 'POST' })
        .then((data: any) => {
          console.log('Server restarted:', data);
        })
    }
  }

  const fetchServerStatus = () => {
    if (params.guid) {
      fetchAPIInternal(`/server/${params.guid}`, { method: 'GET' })
        .then((data: ServerStatusResponse) => {
          if (data.status !== 'Stopped' && isRestartingServer) {
            setIsRestartingServer(false);
          }
          setServerStatus(data);
          setIsLoading(false);
        })
        .catch((error: Error) => {
          console.error('Error fetching server status:', error);
          setIsLoading(false);
        });
    }
  };

  const handleBackToDashboard = () => {
    navigate(`/dashboard`);
  }

  useEffect(() => {
    fetchServerStatus(); // Initial fetch
    const interval = setInterval(fetchServerStatus, 10000); // Fetch every 10 seconds

    return () => clearInterval(interval); // Clear the interval when the component unmounts
  }, [params.guid]);

  const canRestartServer = () => {
    if (serverStatus?.started_at) {
      const startedAt = new Date(serverStatus.started_at + 'Z').getTime();
      const now = new Date().getTime();
      const hoursSinceStart = (now - startedAt) / (1000 * 60 * 60);

      return hoursSinceStart >= 23;
    }
    return false;
  };

  const timeUntilRestart = () => {
    if (serverStatus?.started_at) {
      const startedAt = new Date(serverStatus.started_at + 'Z').getTime();
      const now = new Date().getTime();
      const timeLeft = 23 * 60 * 60 * 1000 - (now - startedAt); // Time left in milliseconds

      const hours = Math.floor(timeLeft / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

      return `${hours}h ${minutes}m ${seconds}s`;
    }
    return '';
  };

  const readyToPlayLabel = () => {
    if (serverStatus?.ready_to_play_in_seconds && serverStatus.ready_to_play_in_seconds > 0) {
      const minutes = Math.ceil(serverStatus.ready_to_play_in_seconds / 60);
      return '(Ready in ' + minutes + ' minute' + (minutes > 1 ? 's' : '') + '...)';
    }
    return '';
  };

  useEffect(() => {
    let timerInterval: NodeJS.Timeout | null = null;

    if (serverStatus?.status === 'Stopped' && !canRestartServer()) {
      timerInterval = setInterval(() => {
        setServerStatus((prevStatus) => ({ ...prevStatus })); // Trigger re-render
      }, 1000);
    }

    return () => {
      if (timerInterval) clearInterval(timerInterval);
    };
  }, [serverStatus?.status, serverStatus?.started_at]);

  return (
    <div className={styles.main}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
        <span className={styles.infoLabel} style={{ marginRight: '10px' }}>What to know more? <i className="bi bi-arrow-right" style={{ marginLeft: '8px', marginRight: '5px' }}></i></span>
        <button className={styles.infoButton} onClick={() => setIsModalOpen(true)}>Info</button>
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <div className={styles.spinner}></div>
          <p className={styles.loading}>Loading...</p>
        </div>
      ) : serverStatus ? (
        <table className={styles.serverTable}>
          <tbody>
            <tr>
              <th>Server page</th>
              <td>
                <a href={`https://miumau.fi/server/${serverStatus.guid}`}>miumau.fi/server/{serverStatus.guid}</a>
                <CopyToClipboard textToCopy={`https://miumau.fi/server/${serverStatus.guid}`} />
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i className={`bi bi-circle-fill ${styles[`status${serverStatus.status}`]}`} style={{ marginRight: '10px' }} />
                  {serverStatus.status}
                  {serverStatus.status === "Initializing" && serverStatus.ready_to_play_in_seconds !== undefined && serverStatus.ready_to_play_in_seconds > 0 &&
                    <span className={styles.readyToPlayLabel}>{readyToPlayLabel()}</span>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <th>Created</th>
              <td>{formatLocalTime(serverStatus.created_at || '')}</td>
            </tr>
            <tr>
              <th>Location</th>
              <td><Location region={serverStatus.region || ''} /></td>
            </tr>
            <tr>
              <th>Restarted</th>
              <td>{serverStatus.created_at !== serverStatus.started_at && (formatLocalTime(serverStatus.started_at || ''))}</td>
            </tr>
            <tr>
              <th>IP address</th>
              <td>{serverStatus.status === 'Running' ? <>{serverStatus.server_ip} <CopyToClipboard textToCopy={serverStatus.server_ip || ''} /></> : null}</td>
            </tr>
            <tr>
              <th>Password</th>
              <td>{serverStatus.status === 'Running' ? <>{serverStatus.password} <CopyToClipboard textToCopy={serverStatus.password || ''} /></> : null}</td>
            </tr>
            <tr>
              <th>Players</th>
              <td>{serverStatus.status === 'Running' ? serverStatus.players : null}</td>
            </tr>
            <tr>
              <th>Playtime left <span style={{ color: '#777', paddingLeft: '4px' }}>(today)</span></th>
              <td>{(serverStatus.status === 'Running' || serverStatus.status === 'Stopped') && <TimerComponent startedAt={serverStatus?.started_at || ''} canRestart={canRestartServer()} />}</td>
            </tr>
            {serverStatus.status === 'Stopped' &&
              <tr>
                <th></th>
                <td>
                  <button disabled={!canRestartServer()} className={styles.restartButton} onClick={() => { handleRestart() }}>
                    {isRestartingServer ?
                      (<div className={styles.spinner}></div>) :
                      (<i className="bi bi-power" style={{ marginRight: '10px' }}></i>)
                    }
                    {canRestartServer() ? 'Restart' : (isRestartingServer ? 'Restarting...' : 'Restart in ' + timeUntilRestart())}
                  </button>
                </td>
              </tr>}
          </tbody>
        </table>
      ) : (
        <p>Server status not available.</p>
      )}
      <span className={styles.discordArea}>
        <span className={styles.discordLabel}>We would ❤️ to hear your feedback! <i className="bi bi-arrow-right" style={{ marginLeft: '8px', marginRight: '14px' }}></i></span>
        <a href="https://discord.gg/FFwxJ229js" target="_blank" className="discord-button" rel="noreferrer">
          <img src="/discord-icon.png" height={15} />
          <span className="feedback-label">Discord</span>
        </a>
      </span>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="miumau Information"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalHeader}>
          <img className={styles.modal_logo} src={"/miumau-logo-transparent-small.png"} alt="Creeper Hub Logo" />
          <h2>ValheinHub.com</h2>
        </div>
        <hr />
        <ul>
          <li>Play for 2 hours each day for free.</li>
          <li>Your world is saved after each session.</li>
          <li>Return to the server page tomorrow to continue playing.</li>
        </ul>
        <h3>More Info</h3>
        <ul>
          <li>The server is on-demand and IP and password change daily.</li>
          <li>Server is automatically near your location.</li>
          <li>We are just getting started. Come and talk with us on <a href="https://discord.gg/FFwxJ229js" target="_blank" rel="noreferrer">Discord</a>!</li>
        </ul>
        <button onClick={() => setIsModalOpen(false)}>Close</button>
      </Modal>
    </div>
  );
};
