import React, { useEffect, useState } from 'react';
import styles from './Dashboard.module.css';
import Location from '../components/Location';
import { fetchAPI, fetchAPIInternal } from '../utils/api';
import { useNavigate } from 'react-router-dom';

interface ServersResponse {
  guid?: string;
  created_at?: string;
  started_at?: string;
  server_ip?: string;
  region?: string;
  password?: string;
  players?: number;
  status?: string;
  ready_to_play_in_seconds?: number;
}

interface StartServerResponse {
  guid?: string; // Replace with actual properties of your response
  // ... other properties
}

export const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [servers, setServers] = useState<ServersResponse[]>([]);
  const [statusFetchedGUIDs, setStatusFetchedGUIDs] = useState(new Set<string>()); // State to store fetched GUIDs

  const unclaimedServerGUIDs = JSON.parse(localStorage.getItem('miumau-anonymous-servers-started') || '[]');

  const getServers = async () => {
    setIsLoading(true);
    try {
      setStatusFetchedGUIDs(new Set()); // Reset fetched GUIDs when loading new servers
      const response = await fetchAPI('/servers');
      console.log('Servers:', response);
      setServers(response);
    } catch (error) {
      console.error('Error loading servers:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isClaiming) {
      getServers();
    }
  }, [isClaiming]);

  useEffect(() => {
    servers.forEach(server => {
      if (server.guid && !statusFetchedGUIDs.has(server.guid)) {
        setStatusFetchedGUIDs(prevGUIDs => new Set(prevGUIDs.add(server.guid || '')));
        fetchServerStatus(server.guid);
      }
    });
  }, [servers]);

  useEffect(() => {
    const claimServers = async () => {
      setIsClaiming(true);
      // Servers are claimed from /claim-server endpoint one-by-one
      for (const guid of unclaimedServerGUIDs) {
        try {
          await fetchAPI(`/claim-server/${guid}`, { method: 'POST' });
          const updatedGUIDs = unclaimedServerGUIDs.filter((g: string) => g !== guid);
          localStorage.setItem('miumau-anonymous-servers-started', JSON.stringify(updatedGUIDs));
        } catch (error) {
          console.error('Error claiming server:', error);
        }
      }
      setIsClaiming(false);
    }
    if (unclaimedServerGUIDs.length > 0 && !isClaiming) {
      claimServers();
    }
  }, [unclaimedServerGUIDs, isClaiming]);

  const fetchServerStatus = (guid: string) => {
    if (guid) {
      fetchAPI(`/server/${guid}`, { method: 'GET' })
        .then((data: ServersResponse) => {
          setServers((servers) => {
            const updatedServers = servers.map(server => {
              if (server.guid === guid) {
                return { ...server, ...data };
              }
              return server;
            });
            return updatedServers;
          });
        })
        .catch((error: Error) => {
          console.error('Error fetching server status:', error);
        });
    }
  };

  const startServer = () => {
    setIsStarting(true); // Set loading to true when the request starts
    fetchAPI('/start-server', { method: 'GET' })
      .then((data: StartServerResponse) => {
        console.log('Server started:', data);
        setIsStarting(false); // Set loading to false when the request is complete
        if (data.guid) {
          navigate(`/server/${data.guid}`);
        }
      })
      .catch((error: Error) => {
        console.error('Error starting server:', error);
        setIsStarting(false); // Set loading to false if there is an error
      });
  };

  return (
    <div className={styles.main}>
      <h1 style={{ marginBottom: '20px' }}><i className="bi bi-server" style={{ marginRight: '10px' }} /> Servers</h1>
      {isClaiming ? (
        <div className={styles.loadingContainer}>
          <div className={styles.tableSpinner}></div>
          <p className={styles.tableLoading}>Claiming servers, please wait...</p>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>GUID</th>
              <th>IP address</th>
              <th>Started At</th>
              <th>Location</th>
              <th>Password</th>
              <th>Players</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!isLoading && servers.length > 0 && (
              servers.map(server => (
                <tr key={server.guid}>
                  <td>{server.guid}</td>
                  <td>{server.server_ip}</td>
                  <td>{server.started_at}</td>
                  <td><Location region={server.region || ''} /></td>
                  <td>{server.password}</td>
                  <td>{server.players}</td>
                  <td>
                    {server.status !== undefined && <>
                      <i className={`bi bi-circle-fill ${styles[`status${server.status}`]}`} style={{ marginRight: '10px' }} />
                      {server.status}
                    </>
                    }
                    {server.status === undefined && <>
                      <div className={styles.loadingContainer}>
                        <div className={styles.tableSpinner}></div>
                        <p className={styles.tableLoading}>Loading...</p>
                      </div>
                    </>}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <button className={styles.openButton} style={{ width: '80px' }} onClick={() => navigate(`/server/${server.guid}`)}><i className={`bi bi-box-arrow-up-right`} style={{ marginRight: '7px' }} />Open</button>
                  </td>
                </tr>
              ))
            )}
            {!isLoading && servers.length === 0 &&
              <tr><td colSpan={6}>No servers found.</td></tr>
            }
            {isLoading &&
              <tr>
                <td colSpan={8}>
                  <div className={styles.loadingContainer}>
                    <div className={styles.tableSpinner}></div>
                    <p className={styles.tableLoading}>Loading...</p>
                  </div>
                </td>
              </tr>
            }
          </tbody>
        </table>
      )}
      <div>
        <button className={styles.startServer} onClick={startServer} disabled={isLoading || isClaiming}>
          {isStarting ?
            (<div className={styles.spinner}></div>) :
            (<i className="bi bi-power" style={{ marginRight: '10px' }}></i>)
          }
          Start new server
        </button>
      </div>
    </div>
  );
};

export default Dashboard;